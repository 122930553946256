import React from "react";
import cx from "classnames";
import { makeStyles } from "@material-ui/core";
import Proptypes from "prop-types";

export default function BlueBox({
  children,
  className,
  onMouseLeave,
  addDecoration,
  top,
  height,
}) {
  const decorationHeight = height || "70%";
  const decorationTop = top || "15%";

  const classes = useStyle({
    decorationTop,
    decorationHeight,
  });

  return (
    <div
      onMouseLeave={onMouseLeave}
      className={cx(
        classes.container,
        addDecoration && classes.addDecoration,
        className
      )}
    >
      {children}
    </div>
  );
}

BlueBox.propTypes = {
  children: Proptypes.node,
  className: Proptypes.string,
  onMouseLeave: Proptypes.func,
  addDecoration: Proptypes.bool,
  top: Proptypes.string,
  height: Proptypes.string,
};

BlueBox.defaultProps = {
  addDecoration: false,
  top: null,
  height: null,
};

const useStyle = makeStyles(({ palette }) => ({
  container: {
    position: "relative",
    height: "100%",
    border: `1px solid #495a68`,
    background: "rgba(13, 22, 37, 0.8)",
    backdropFilter: "blur(10px)",
    boxShadow: `0 0 5px #495a68`,
    borderRadius: "2rem",
  },
  addDecoration: {
    "&::after": {
      content: "''",
      position: "absolute",
      left: "-3px",
      top: ({ decorationTop }) => decorationTop,
      width: "7px",
      height: ({ decorationHeight }) => decorationHeight,
      background: palette.common.white,
      borderRadius: "3rem",
      filter: `drop-shadow( 1px 1px 10px ${palette.primary.light})`,
    },
  },
}));
