import partners50 from "../assets/partners/partners50.png";
import altura from "../assets/partners/altura.png";
import beam from "../assets/partners/beam.png";
import coinTelegraph from "../assets/partners/coinTelegraph.png";
import cointribune from "../assets/partners/cointribune.png";
import fayc from "../assets/partners/FAYC.png";
import gains from "../assets/partners/gains.png";
import gamefi from "../assets/partners/gamefi.png";
import gameswift from "../assets/partners/gameswift.png";
import immutable from "../assets/partners/immutable.png";
import portal from "../assets/partners/portal.png";
import quadrilium from "../assets/partners/quadrilium.png";
import snz from "../assets/partners/snz.png";
import superVerse from "../assets/partners/superVerse.png";

// Weapons

import weaponKryo from "../assets/resources/weaponKryo.png";
import weaponLuxion from "../assets/resources/weaponLuxion.png";
import weaponNaya from "../assets/resources/weaponNaya.png";
import resourcesRTG from "../assets/resources/resourcesRTG.png";
import resourcesHQ from "../assets/resources/resourcesHQ.png";
import extractors from "../assets/resources/extractors.png";
import workshop from "../assets/resources/workshop.png";
import engineeringBay from "../assets/resources/engineeringBay.png";

import ogemResource from "../assets/resources/ogem.png";
import oxyaResource from "../assets/resources/oxya.png";
import oxyzResource from "../assets/resources/oxyz.png";

// planets
import planetLuxion from "../assets/lands/planet-luxion.png";
import planetKryo from "../assets/lands/planet-kryo.png";
import planetNaya from "../assets/lands/planet-naya.png";
import landKryo from "../assets/lands/land-kryo.png";
import landNaya from "../assets/lands/land-naya.png";
import landLuxion from "../assets/lands/land-luxion.png";

import bgLandsKryo from "../assets/backgrounds/ourlands-kryo.png";
import bgLandsKryoMobile from "../assets/backgrounds/mobile/ourlands-kryo.png";
import bgLandsLuxion from "../assets/backgrounds/ourlands-luxion.png";
import bgLandsLuxionMobile from "../assets/backgrounds/mobile/ourlands-luxion.png";
import bgLandsNaya from "../assets/backgrounds/ourlands-naya.png";
import bgLandsNayaMobile from "../assets/backgrounds/mobile/ourlands-naya.png";

// ecosystem
import gatemap from "../assets/ecosystem/gatemap.jpg";
import headquarter from "../assets/ecosystem/dashboard.jpg";
import roquelite from "../assets/ecosystem/roguelite2.jpg";

import gatemapVideo from "../assets/ecosystem/gatemap.mp4";
import roqueliteVideo from "../assets/ecosystem/roquelite2.mp4";
import headquartersVideo from "../assets/ecosystem/headquarters.mp4";

// gaming
import playGaming from "../assets/gaming/play.png";
import lootGaming from "../assets/gaming/loot.png";
import extractGaming from "../assets/gaming/extract.png";
import interactGaming from "../assets/gaming/interact.png";
import collaborativeGaming from "../assets/gaming/collaborative.png";
import marketplaceGaming from "../assets/gaming/marketplace.png";

// wallets
import metamaskWallet from "../assets/wallets/metamask.png";
import walletConnectWallet from "../assets/wallets/walletConnect.png";
import coinbaseWallet from "../assets/wallets/coinbase.png";
import ledgerWallet from "../assets/wallets/ledger.png";
import trustwallet from "../assets/wallets/trustwallet.png";
import walletUnknow1 from "../assets/wallets/walletunknow1.png";
import walletUnknow2 from "../assets/wallets/walletunknow2.png";
import walletUnknow3 from "../assets/wallets/walletunknow3.png";
import walletUnknow4 from "../assets/wallets/walletunknow4.png";
import { appUrl, whitelistUrl } from "./text";

// travelling
import travelling1 from "../assets/backgrounds/travelling/travelling1.png";
import travelling2 from "../assets/backgrounds/travelling/travelling2.png";
import travelling3 from "../assets/backgrounds/travelling/travelling3.png";
import travelling4 from "../assets/backgrounds/travelling/travelling4.png";

export const travellingImages = [
  travelling1,
  travelling2,
  travelling3,
  travelling4,
];

export const imgWithTextEconomyAssets = [
  {
    key: 1,
    title: "Ogem",
    subtitle: "Game Currency",
    details: (
      <span>
        Rare gemstone fragments infused with metastable, unrefined Oxya. Highly
        sought after by hunters, these fragments are essential for crafting
        precious items and hold significant value in the ecosystem.
      </span>
    ),
    img: ogemResource,
  },
  {
    key: 2,
    title: "Oxya",
    subtitle: "Premium Currency",
    details: (
      <span>
        A powerful energy source in stabilized solid form. Oxya chips sublimate
        under controlled conditions, making it both a potentially dangerous
        chain-reaction material and a highly valuable energy currency. This dual
        nature positions Oxya as a cornerstone of the ecosystem’s economy.
      </span>
    ),
    img: oxyaResource,
  },
  {
    key: 3,
    title: "Oxyz",
    subtitle: "Ecosystem Token",
    details: (
      <span>
        The Federation employs a digital-physical hybrid currency known as $Oxyz
        to regulate the monetary system across all planets. This system enables
        hunters to store value securely without the risks of carrying large
        quantities of Oxya.
      </span>
    ),
    img: oxyzResource,
  },
];

export const imgPartners = [
  {
    key: 1,
    img: partners50,
    alt: "Partners 50",
  },
  {
    key: 2,
    img: altura,
    alt: "Altura",
  },
  {
    key: 3,
    img: beam,
    alt: "Beam",
  },
  {
    key: 4,
    img: coinTelegraph,
    alt: "Coin Telegraph",
  },
  {
    key: 5,
    img: cointribune,
    alt: "Cointribune",
  },
  {
    key: 6,
    img: fayc,
    alt: "FAYC",
  },
  {
    key: 7,
    img: gains,
    alt: "Gains",
  },
  {
    key: 8,
    img: gamefi,
    alt: "GameFi",
  },
  {
    key: 9,
    img: gameswift,
    alt: "Gameswift",
  },
  {
    key: 10,
    img: immutable,
    alt: "Immutable",
  },
  {
    key: 11,
    img: portal,
    alt: "Portal",
  },
  {
    key: 12,
    img: quadrilium,
    alt: "Quadrilium",
  },
  {
    key: 13,
    img: snz,
    alt: "SNZ",
  },
  {
    key: 14,
    img: superVerse,
    alt: "SuperVerse",
  },
];

export const imgWeaponsResources = [
  {
    key: 1,
    step: 1,
    name: "Raw Materials HQ",
    img: resourcesHQ,
    id: "resourcesRockHQ",
    defaultSelected: true,
    title: "Raw materials HQ",
    details: (
      <span>
        <span style={{ fontWeight: 600 }}>
          Alkar, Steelvar, Electrium and Vrilium
        </span>{" "}
        are used to craft and upgrade extractors, providing land owners with the
        most advanced tools for resource extraction.
      </span>
    ),
  },
  {
    key: 2,
    step: 2,
    name: "Machine Gun - JCMG87",
    img: weaponKryo,
    id: "Machine Gun - JCMG87",
    defaultSelected: false,
    title: "Machine Gun - JCMG87",
    details:
      "The JCMG87 (short for Jota Corp Machine Gun 1687) is a light machine gun designed by Jota Corp. This weapon boasts rapid firing and reloading speeds but is highly ammunition-hungry. Despite dealing relatively low damage, it is particularly effective against Kapras Green.",
  },
  {
    key: 3,
    step: 3,
    name: "Sniper - JCSG42",
    img: weaponNaya,
    id: "Sniper - JCSG42",
    defaultSelected: false,
    title: "Sniper - JCSG42",
    details:
      "A modified weapon from Luxion based on an old JCSG42 model (Jota Corp Shoot Gun 1642) originally developed during the era of Klaas's father, Whilce Jota. While the weapon’s reload time is relatively lengthy, this lightweight sniper gained significant popularity for being one of the highest damage-dealing guns.",
  },
  {
    key: 4,
    step: 4,
    name: "Workshop",
    img: workshop,
    id: "workshop",
    defaultSelected: false,
    title: "Workshop",
    details: (
      <span>
        Used to craft and upgrade{" "}
        <span style={{ fontWeight: 600 }}>weapons</span> and{" "}
        <span style={{ fontWeight: 600 }}>equipment</span> that players can use
        in-game.
      </span>
    ),
  },
  {
    key: 5,
    step: 5,
    name: "Engineering Bay",
    img: engineeringBay,
    id: "Engineering Bay",
    defaultSelected: false,
    title: "Engineering Bay",
    details:
      "Used to craft and upgrade utility items that open up yield opportunities such as Extraction Bots and Combat Robots.",
  },
  {
    key: 6,
    step: 6,
    name: "Extractor",
    img: extractors,
    id: "Extractor",
    defaultSelected: false,
    title: "Extractor",
    details:
      "Alkar, Steelvar and Electrium extractors collect raw materials from a Land passively.",
  },
  {
    key: 7,
    step: 7,
    name: "Raw Materials Road To Genesis",
    img: resourcesRTG,
    id: "resourcesRockRtG",
    defaultSelected: false,
    title: "Raw materials Road To Genesis",
    details: (
      <span>
        <span style={{ fontWeight: 600 }}>
          Sylvium, Kalium, Tezcalite and Aurum
        </span>{" "}
        are essential for crafting and upgrading in-game items, providing
        hunters with enhanced weapons and armor.
      </span>
    ),
  },
  {
    key: 8,
    step: 1,
    name: "Raw Materials HQ",
    img: resourcesHQ,
    id: "resourcesRockHQ",
    defaultSelected: false,
    title: "Raw materials HQ",
    details:
      "Alkar, Steelvar and Electrium have specific properties that can be used to craft and upgrade in-game items.",
  },
  {
    key: 9,
    step: 2,
    name: "Machine Gun - JCMG87",
    img: weaponKryo,
    id: "Machine Gun - JCMG87",
    defaultSelected: false,
    title: "Machine Gun - JCMG87",
    details:
      "The JCMG87 (short for Jota Corp Machine Gun 1687) is a light machine gun designed by Jota Corp. This weapon boasts rapid firing and reloading speeds but is highly ammunition-hungry. Despite dealing relatively low damage, it is particularly effective against Kapras Green.",
  },
  {
    key: 10,
    step: 3,
    name: "Sniper - JCSG42",
    img: weaponNaya,
    id: "Sniper - JCSG42",
    defaultSelected: false,
    title: "Sniper - JCSG42",
    details:
      "A modified weapon from Luxion based on an old JCSG42 model (Jota Corp Shoot Gun 1642) originally developed during the era of Klaas's father, Whilce Jota. While the weapon’s reload time is relatively lengthy, this lightweight sniper gained significant popularity for being one of the highest damage-dealing guns.",
  },
  {
    key: 11,
    step: 4,
    name: "Workshop",
    img: workshop,
    id: "workshop",
    defaultSelected: false,
    title: "Workshop",
    details: (
      <span>
        Used to craft and upgrade{" "}
        <span style={{ fontWeight: 600 }}>weapons</span> and{" "}
        <span style={{ fontWeight: 600 }}>equipment</span> that players can use
        in-game.
      </span>
    ),
  },
  {
    key: 12,
    step: 5,
    name: "Engineering Bay",
    img: engineeringBay,
    id: "Engineering Bay",
    defaultSelected: false,
    title: "Engineering Bay",
    details:
      "Used to craft and upgrade utility items that open up yield opportunities such as Extraction Bots and Combat Robots.",
  },
  {
    key: 13,
    step: 6,
    name: "Extractor",
    img: extractors,
    id: "Extractor",
    defaultSelected: false,
    title: "Extractor",
    details:
      "Alkar, Steelvar and Electrium extractors collect raw materials from a Land passively.",
  },
  {
    key: 14,
    step: 7,
    name: "Raw Materials Road To Genesis",
    img: resourcesRTG,
    id: "resourcesRockRtG",
    defaultSelected: false,
    title: "Raw materials Road To Genesis",
    details: (
      <span>
        <span style={{ fontWeight: 600 }}>
          Sylvium, Kalium, Tezcalite and Aurum
        </span>{" "}
        are essential for crafting and upgrading in-game items, providing
        hunters with enhanced weapons and armor.
      </span>
    ),
  },
];

export const imgLands = [
  {
    key: 0,
    step: 1,
    fakeStep: 1,
    name: "planet luxion",
    imgPlanet: planetLuxion,
    imgLand: landLuxion,
    imgBg: bgLandsLuxion,
    imgBgMobile: bgLandsLuxionMobile,
    id: "planet-luxion",
    defaultSelected: false,
    style: { boxShadow: "0px 0px 200px 20px rgba(199, 57, 37, .75)" },
    title: (
      <span
        style={{
          textTransform: "uppercase",
          fontSize: "2.2rem",
          color: "white",
          fontWeight: 500,
          fontFamily: "Akira",
        }}
      >
        Luxio<span style={{ color: "rgb(186, 23, 0)" }}>n</span>
      </span>
    ),
    detailsBox:
      "The magmatic wasteland’s volcanoes produce greenhouse gasses that maintain temperatures over 360 degrees celsius.",
  },
  {
    key: 1,
    step: 2,
    fakeStep: 2,
    name: "planet kryo",
    imgPlanet: planetKryo,
    imgLand: landKryo,
    imgBg: bgLandsKryo,
    imgBgMobile: bgLandsKryoMobile,
    id: "planet-kryo",
    defaultSelected: true,
    style: { boxShadow: "0px 0px 200px 20px rgba(38, 99, 153, 1)" },
    title: (
      <span
        style={{
          textTransform: "uppercase",
          fontSize: "2.2rem",
          color: "white",
          fontWeight: 500,
          fontFamily: "Akira",
        }}
      >
        Kry<span style={{ color: "rgb(15, 190, 255)" }}>o</span>
      </span>
    ),
    detailsBox:
      "Snowy Kryo’s mountain range is the home of mineral-rich caves and the forgotten laboratories.",
  },
  {
    key: 2,
    step: 3,
    fakeStep: 3,
    name: "planet naya",
    imgPlanet: planetNaya,
    imgLand: landNaya,
    imgBg: bgLandsNaya,
    imgBgMobile: bgLandsNayaMobile,
    id: "planet-naya",
    defaultSelected: false,
    style: { boxShadow: "0px 0px 200px 20px rgba(199, 134, 73, .75)" },
    title: (
      <span
        style={{
          textTransform: "uppercase",
          fontSize: "2.2rem",
          color: "white",
          fontWeight: 500,
          fontFamily: "Akira",
        }}
      >
        Nay<span style={{ color: "rgb(197, 123, 19)" }}>a</span>
      </span>
    ),
    detailsBox:
      "Naya’s dunes and arid plains are inhabited by mysterious spirits that bring life to the desert.",
  },
];

export const imgEcosystem = [
  {
    key: 0,
    selected: false,
    title: "Road to Genesis",
    subTitle: "",
    description: (
      <span>
        Cooperative third-person shooter that blends intense,{" "}
        <span style={{ fontWeight: 600 }}>tactical combat</span> with{" "}
        <span style={{ fontWeight: 600 }}>rich dungeon exploration</span>,
        challenging you with every Rundown of a{" "}
        <span style={{ fontWeight: 600 }}>high-stake extraction looter.</span>{" "}
        You'll face{" "}
        <span style={{ fontWeight: 600 }}>ever-changing environments</span> that
        test your skills, requiring strategic use of consumables,{" "}
        <span style={{ fontWeight: 600 }}>careful health management</span>, and
        precise ammunition control to survive.
      </span>
    ),
    img: roquelite,
    video: roqueliteVideo,
    textBtnOne: "Avatars",
    onClickBtnOne: () => {
      window.open("https://opensea.io/collection/oxyaoriginproject", "_blank");
    },
    textBtnTwo: "Play Now",
    onClickBtnTwo: () => {
      window.open(whitelistUrl);
    },
    disabledBtnOne: false,
    disabledBtnTwo: false,
  },
  {
    key: 1,
    selected: false,
    title: "HeadQuarters",
    subTitle: "",
    description: (
      <span>
        The HQ launch introduces{" "}
        <span style={{ fontWeight: 600 }}>
          management and strategy elements
        </span>{" "}
        to the Oxya Origin ecosystem,{" "}
        <span style={{ fontWeight: 600 }}>enhancing $OXYZ utility</span> and
        paving the way for future{" "}
        <span style={{ fontWeight: 600 }}>cross-game interoperability.</span> As
        a web-based management hub, the HQ enables players to{" "}
        <span style={{ fontWeight: 600 }}>
          manage resources, craft items, and engage in strategic mining
          activities
        </span>{" "}
        that integrate directly with Road to Genesis and future Oxya titles.
      </span>
    ),
    img: headquarter,
    video: headquartersVideo,
    textBtnOne: "Lands",
    onClickBtnOne: () => {
      window.open("https://opensea.io/collection/gatesofoxya-lands", "_blank");
    },
    textBtnTwo: "HeadQuarters",
    onClickBtnTwo: () => {},
    disabledBtnOne: false,
    disabledBtnTwo: true,
  },
  {
    key: 2,
    selected: true,
    title: "Gatemap",
    subTitle: "",
    description:
      "Providing a bird’s eye view of the three planets and the Lands that constitute them, the GateMap is the intergalactic traveler and real estate mogul’s most precious tool.\n\n" +
      "Players can explore all three planets, visit and customise their Lands and delve deeper than ever into the rich universe of the Gates of Oxya.",
    img: gatemap,
    video: gatemapVideo,
    textBtnOne: "Buy Lands",
    onClickBtnOne: () => {
      window.open("https://opensea.io/collection/gatesofoxya-lands", "_blank");
    },
    textBtnTwo: "Visit Gatemap",
    onClickBtnTwo: () => {
      window.open("https://gatemap.oxyaorigin.com", "_blank");
    },
    disabledBtnOne: false,
    disabledBtnTwo: false,
  },
];

export const imgCarouselGaming = [
  {
    key: 0,
    alt: "Play\n",
    img: playGaming,
    title: (
      <span>
        <span style={{ fontWeight: 600, fontFamily: "Akira" }}>{"Play\n"}</span>
        Up to 4 players
      </span>
    ),
  },
  {
    key: 1,
    alt: "Loot",
    img: lootGaming,
    defaultSelected: true,
    title: (
      <span>
        <span style={{ fontWeight: 600, fontFamily: "Akira" }}>{"Loot\n"}</span>
        Blueprints & Resources
      </span>
    ),
  },
  {
    key: 2,
    alt: "Extract",
    img: extractGaming,
    title: (
      <span>
        <span style={{ fontWeight: 600, fontFamily: "Akira" }}>
          {"Extract\n"}
        </span>
        Risk it all or play it safe
      </span>
    ),
  },
  {
    key: 3,
    alt: "trade you way to the top",
    img: marketplaceGaming,
    title: (
      <span>
        <span style={{ fontWeight: 600, fontFamily: "Akira" }}>
          {"TRADE\n"}
        </span>{" "}
        your way to the top
      </span>
    ),
  },
  {
    key: 4,
    alt: "collaborate with other players",
    img: collaborativeGaming,
    title: (
      <span>
        <span style={{ fontWeight: 600, fontFamily: "Akira" }}>
          {"COLLABORATE\n"}
        </span>{" "}
        with other players
      </span>
    ),
  },
  {
    key: 5,
    alt: "interact with partners",
    img: interactGaming,
    title: (
      <span>
        <span style={{ fontWeight: 600, fontFamily: "Akira" }}>
          {"INTERACT\n"}
        </span>{" "}
        with partners
      </span>
    ),
  },
];

export const imgWallets = [
  {
    key: 0,
    alt: "metamask wallet",
    img: metamaskWallet,
  },
  {
    key: 1,
    alt: "walletConnect wallet",
    img: walletConnectWallet,
  },
  {
    key: 2,
    alt: "coinbase wallet",
    img: coinbaseWallet,
  },
  {
    key: 3,
    alt: "ledger wallet",
    img: ledgerWallet,
  },
  {
    key: 4,
    alt: "trust wallet",
    img: trustwallet,
  },
  {
    key: 5,
    alt: "Unknow wallet1",
    img: walletUnknow1,
  },
  {
    key: 6,
    alt: "Unknow wallet2",
    img: walletUnknow2,
  },
  {
    key: 7,
    alt: "Unknow wallet3",
    img: walletUnknow3,
  },
  {
    key: 8,
    alt: "Unknow wallet4",
    img: walletUnknow4,
  },
];
