import React from "react";
import Proptypes from "prop-types";
import { useHistory } from "react-router-dom";
import BlueBox from "../../atoms/BlueBox/blue-box";
import ButtonWithIcon from "../../molecules/ButtonWithIcon/button-with-icon";
import { makeStyles, Typography, Box } from "@material-ui/core";

import iconEcosystem from "../../../assets/icons/ecosystem.svg";

import { sizeMobileForOurLands } from "../../../constants/size";

export default function BoxDetailsPlanet({ className, landSelected }) {
  const classes = useStyle({});
  const history = useHistory();

  const handleClickEcosystem = () => {
    history.push("/ecosystem");
  };

  return (
    <BlueBox className={(classes.root, className)}>
      <Typography variant="body1">Planet</Typography>
      <Typography variant="h3">{landSelected.title}</Typography>
      <Box className={classes.wrapperContentDetails}>
        <Box className={classes.containerDetailsBoxMobile}>
          <Typography variant="body1" className={classes.textDetails}>
            {landSelected.detailsBox}
          </Typography>
          <ButtonWithIcon
            className={classes.btnResponsive}
            hrefIcon={iconEcosystem}
            text="Ecosystem"
            onClick={handleClickEcosystem}
          />
        </Box>
        <Box className={classes.containerDetailsDesktop}>
          <Typography variant="body1" style={{ fontSize: "1.6rem" }}>
            {landSelected.detailsBox}
          </Typography>
        </Box>
      </Box>
      <ButtonWithIcon
        hrefIcon={iconEcosystem}
        text="Ecosystem"
        className={classes.btn}
        onClick={handleClickEcosystem}
      />
    </BlueBox>
  );
}

BoxDetailsPlanet.propTypes = {
  className: Proptypes.string,
  landSelected: Proptypes.object,
};

BoxDetailsPlanet.defaultProps = {};

const useStyle = makeStyles(({ palette, breakpoints }) => ({
  root: {},
  btn: {
    margin: "2rem auto",
    padding: "0 1rem",
    [breakpoints.down("sm")]: {
      display: "none",
    },
    [breakpoints.down(sizeMobileForOurLands)]: {
      display: "flex",
    },
  },
  wrapperContentDetails: {
    [breakpoints.down("sm")]: {
      display: "flex",
      margin: "2rem 0",
      width: "100%",
      justifyContent: "space-between",
    },
    [breakpoints.down(sizeMobileForOurLands)]: {
      flexDirection: "column",
      justifyContent: "center",
      alignItem: "center",
    },
  },
  containerDetailsDesktop: {
    [breakpoints.down("sm")]: {
      display: "none",
    },
  },
  containerDetailsBoxMobile: {
    display: "none",
    [breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      gap: "2rem",
      width: "70%",
      alignItems: "center",
    },
    [breakpoints.down(sizeMobileForOurLands)]: {
      width: "100%",
      textAlign: "center",
      padding: "0 1rem",
    },
  },
  textDetails: {
    [breakpoints.down("sm")]: {
      fontSize: "1.6rem",
    },
  },
  btnResponsive: {
    [breakpoints.down("sm")]: {
      width: "18rem",
      padding: 0,
    },
    [breakpoints.down(sizeMobileForOurLands)]: {
      display: "none",
    },
  },
  contentResBuild: {
    [breakpoints.down(sizeMobileForOurLands)]: {
      margin: "auto",
      textAlign: "center",
      alignItem: "center",
      justifyContent: "center",
    },
  },
}));
