import React from "react";
import cx from "classnames";
import Proptypes from "prop-types";

import iconHome from "../../../assets/icons/home.svg";

import ButtonPerso from "../../atoms/ButtonPerso/button-perso";
import { Typography, makeStyles } from "@material-ui/core";

export default function ButtonWithIcon({
  className,
  withBorder,
  text,
  hrefIcon,
  icon,
  disabled,
  isLoading,
  shrink,
  small,
  onClick,
  onMouseEnter,
  onMouseLeave,
  sx,
  widthIcon,
  heightIcon,
}) {
  const classes = useStyle();
  return (
    <ButtonPerso
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      disabled={disabled}
      shrink={shrink}
      small={small}
      isLoading={isLoading}
      className={cx(classes.root, className)}
      withBorder={withBorder}
      sx={sx}
    >
      {!!icon ? (
        icon
      ) : (
        <img src={hrefIcon} alt="icon" width={widthIcon} height={heightIcon} />
      )}
      <Typography>{text}</Typography>
    </ButtonPerso>
  );
}

const useStyle = makeStyles(({ palette }) => ({
  root: {
    "& span": {
      display: "flex",
      alignItems: "center",
      gap: "0.5rem",
      transition: "none",
    },
    "& p": {
      fontWeight: 600,
      fontSize: "1.6rem",
      transition: "0.2s all ease-in-out",
    },
    "&:hover": {
      "& p": {
        color: palette.primary.light,
      },
    },
  },
}));

ButtonWithIcon.propTypes = {
  className: Proptypes.string,
  hrefIcon: Proptypes.node,
  icon: Proptypes.node,
  disabled: Proptypes.bool,
  isLoading: Proptypes.bool,
  shrink: Proptypes.bool,
  small: Proptypes.bool,
  withBorder: Proptypes.bool,
  onMouseLeave: Proptypes.func,
  onMouseEnter: Proptypes.func,
  widthIcon: Proptypes.number,
  heightIcon: Proptypes.number,
};

ButtonWithIcon.defaultProps = {
  onClick: () => {},
  onMouseLeave: () => {},
  onMouseEnter: () => {},
  icon: null,
  disabled: false,
  shrink: false,
  small: false,
  isLoading: false,
  hrefIcon: iconHome,
  withBorder: true,
  widthIcon: 33,
  heightIcon: 33,
};
