import React from "react";
import cx from "classnames";
import Proptypes from "prop-types";

import logoHQ from "../../../assets/logoHq.png";
import logoRTG from "../../../assets/logoRtg.png";

import ButtonPerso from "../../atoms/ButtonPerso/button-perso";
import { Box, makeStyles } from "@material-ui/core";
import { whitelistUrl } from "../../../constants/text";

export default function ButtonsHQRtg({ className }) {
  const classes = useStyle();

  const handleClickHQ = () => {
    window.open("http://app.oxyaorigin.com", "_blank");
  };

  const handleClickRTG = () => {
    window.open(whitelistUrl, "_blank");
  };
  return (
    <Box
      display="flex"
      flexDirection="column"
      gridColumnGap={20}
      gridRowGap={20}
    >
      <ButtonPerso
        className={cx(classes.root, className)}
        onClick={handleClickHQ}
        withBorder={false}
      >
        <img alt="hq" src={logoHQ} />
      </ButtonPerso>
      <ButtonPerso
        className={cx(classes.root, className)}
        onClick={handleClickRTG}
        withBorder={false}
      >
        <img alt="rtg" src={logoRTG} />
      </ButtonPerso>
    </Box>
  );
}

const useStyle = makeStyles(({ palette }) => ({
  root: {
    background: "transparent",
    margin: "0 auto",
    border: "none",
    boxShadow: "none",
    transition: "all 0.3s ease",
    "&:hover": {
      "& img": {
        filter: "brightness(0) invert(1) drop-shadow( 1px 1px 10px #fff)",
      },
    },
    "& img": {
      width: "75px",
    },
  },
}));

ButtonsHQRtg.propTypes = {
  className: Proptypes.string,
};
