import React from "react";
import Footer from "../../components/organisms/Footer/footer";
import ReactGA from "react-ga4";

const TRACKIG_GA4ID = "G-DN716NQJPC";

ReactGA.initialize(TRACKIG_GA4ID);

function App({ children }) {
  return (
    <>
      <div style={{ background: "#0d1625" }}>
        <main>{children}</main>
        <Footer />
      </div>
    </>
  );
}

export default App;
