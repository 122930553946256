import React, { useEffect } from "react";
import Proptypes from "prop-types";

import cx from "classnames";
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  Typography,
  Link,
  makeStyles,
} from "@material-ui/core";

import logoOxya from "../../../assets/logo-oxya.png";
import iconTwitter from "../../../assets/social/twitter.svg";
import iconDiscord from "../../../assets/social/discord.svg";

import MenuIcon from "@material-ui/icons/Menu";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import CloseIcon from "@material-ui/icons/Close";
import { menu } from "../../../constants/menu";

import ButtonWithIcon from "../../molecules/ButtonWithIcon/button-with-icon";
import ButtonOxyaToken from "../../molecules/ButtonOxyaToken/button-oxya-token";
import { timeToAppear } from "../../../constants/animation";
import { useHistory } from "react-router-dom";
import ButtonPerso from "../../atoms/ButtonPerso/button-perso";

import { appUrl } from "../../../constants/text";
import ButtonsHQRtg from "../../molecules/ButtonsHQRtg/buttons-hq-rtg";

export default function NavBar({ className, canBeVisible, noWait }) {
  const classes = useStyle({});
  const [openMenuMobile, setOpenMenuMobile] = React.useState(false);
  const history = useHistory();

  const handleCloseMenuMobile = () => {
    setOpenMenuMobile(false);
  };

  const handleOpenMenuMobile = () => {
    setOpenMenuMobile(true);
  };

  const handleClickLinktree = () => {
    window.open("https://lnkfi.re/OxyaOrigin", "_blank");
  };

  const handleClickApp = () => {
    window.open(`${appUrl}`);
  };

  useEffect(() => {
    const menuComponent = document.getElementById("social");
    const logoDesktop = document.getElementById("logoDesktop");

    if (noWait) {
      menuComponent.classList.add(classes.translate0);
      logoDesktop.classList.add(classes.translate0);
    }
    if (canBeVisible) {
      const timeOut = setTimeout(() => {
        menuComponent.classList.add(classes.translate0);
        logoDesktop.classList.add(classes.translate0);
      }, [timeToAppear]); //TODO CHANGE BY TmeToAppear

      return () => {
        clearTimeout(timeOut);
      };
    }
  }, [canBeVisible, classes.translate0]);
  return (
    <>
      <Box className={cx(classes.rootDesktop, className)}>
        <Link href="/" id="logo">
          <img
            src={logoOxya}
            alt="logo"
            id="logoDesktop"
            className={classes.logoDesktop}
          />
        </Link>
        <Box className={cx(classes.social, classes.translateRight)} id="social">
          <Link
            href="https://twitter.com/OxyaOrigin"
            style={{ display: "inline-flex" }}
            target="_blank"
          >
            <img
              className={classes.imgSocial}
              src={iconTwitter}
              width={20}
              height={21}
              alt="twitter"
            />
          </Link>
          <Link
            style={{ display: "inline-flex" }}
            href="https://discord.com/invite/oxyaorigin"
            target="_blank"
          >
            <img
              className={classes.imgSocial}
              src={iconDiscord}
              width={20}
              height={21}
              alt="discord"
            />
          </Link>
          <ButtonWithIcon
            onClick={handleClickLinktree}
            className={classes.btnLinkTree}
            text="Our Links"
            icon={
              <Box className={classes.wrapperIcon}>
                <ArrowRightAltIcon />
              </Box>
            }
          />
          <ButtonPerso onClick={handleClickApp} className={classes.btnRegister}>
            Play
          </ButtonPerso>
        </Box>
      </Box>
      <Box className={cx(classes.rootMobile)}>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          style={{ marginRight: "1rem" }}
          onClick={handleOpenMenuMobile}
        >
          <MenuIcon fontSize="large" />
        </IconButton>
        <Link href="/" className={classes.linkLogoMobile}>
          <img src={logoOxya} alt="logo" />
        </Link>
        <ButtonPerso onClick={handleClickApp} className={classes.btnRegister}>
          App
        </ButtonPerso>
      </Box>
      <Drawer
        className={classes.drawer}
        anchor="left"
        open={openMenuMobile}
        classes={{
          paper: classes.drawerPaper,
        }}
        onClose={handleCloseMenuMobile}
      >
        <Box
          display="flex"
          flexDirection="column"
          style={{ padding: "3rem 3rem 0" }}
        >
          <IconButton
            aria-label="close"
            onClick={handleCloseMenuMobile}
            style={{ justifyContent: "flex-end" }}
          >
            <CloseIcon style={{ color: "white" }} fontSize="large" />
          </IconButton>
          <img src={logoOxya} alt="logo" />
          <Box
            className={classes.wrapperLinksMenuMobile}
            display="flex"
            flexDirection="column"
            gridColumnGap="2rem"
            gridRowGap="2rem"
          >
            {menu.map((itemMenu) => (
              <button
                className={classes.itemMenu}
                type="button"
                key={`menu-mobile-${itemMenu.key}`}
                onClick={() => {
                  history.push(itemMenu.to);
                }}
              >
                <img
                  width={itemMenu.widthIcon}
                  height={itemMenu.heightIcon}
                  src={itemMenu.icon}
                  alt={itemMenu.alt}
                />
                <Typography variant="body1">{itemMenu.title}</Typography>
              </button>
            ))}
          </Box>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-around"
          className={classes.wrapperBottomMenuMobile}
        >
          <Box
            display="flex"
            flexDirection="column"
            gridColumnGap="2rem"
            gridRowGap="2rem"
            justifyContent="center"
            alignItems="center"
          >
            <Box className={classes.social}>
              <Link
                style={{ display: "inline-flex" }}
                href="https://twitter.com/OxyaOrigin"
              >
                <img
                  className={classes.imgSocial}
                  src={iconDiscord}
                  width={33}
                  height={31}
                  alt="discord"
                />
              </Link>
              <Link
                href="https://twitter.com/OxyaOrigin"
                style={{ display: "inline-flex" }}
              >
                <img
                  className={classes.imgSocial}
                  src={iconTwitter}
                  width={28}
                  height={29}
                  alt="twitter"
                />
              </Link>
            </Box>
            <ButtonWithIcon
              className={classes.btnLinkTree}
              text="Our Links"
              onClick={handleClickLinktree}
              icon={
                <Box className={classes.wrapperIcon}>
                  <ArrowRightAltIcon />
                </Box>
              }
            />
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box style={{ padding: "2rem" }}>
            <ButtonsHQRtg />
          </Box>
        </Box>
      </Drawer>
    </>
  );
}

NavBar.propTypes = {
  canBeVisible: Proptypes.bool,
  noWait: Proptypes.bool,
  noAuth: Proptypes.bool,
};

NavBar.defaultProps = {
  canBeVisible: false,
  noWait: true,
  noAuth: false,
};

const useStyle = makeStyles(({ palette, breakpoints }) => ({
  rootDesktop: {
    display: "none",
    justifyContent: "space-between",
    alignItems: "center",
    [breakpoints.up("sm")]: {
      display: "flex",
    },
  },
  logoDesktop: {
    width: "30rem",
    opacity: 0,
    transform: "translate(-100px)",
    transition: "0.2s all ease-in-out",
    [breakpoints.down(880)]: {
      width: "20rem",
    },
  },
  rootMobile: {
    display: "none",
    justifyContent: "flex-start",
    alignItems: "center",
    [breakpoints.down("xs")]: {
      display: "flex",
    },
    "& svg": {
      fontSize: "3rem",
    },
  },
  boxContentMobile: {
    justifyContent: "flex-end",
  },
  linkLogoMobile: {
    flex: "5",
    display: "flex",
    justifyContent: "center",
    marginRight: "5rem",
    /*[breakpoints.down(560)]: {
      display: "none",
    },*/
    "& img": {
      width: "20rem",
    },
    [breakpoints.down(580)]: {
      display: "none",
    },
  },
  social: {
    display: "flex",
    alignItems: "center",
    gap: "2rem",
    transition: "0.2s all ease-in-out",
    "& img": {
      transition: "0.2s all ease-in-out",
      "&:hover": {
        opacity: 0.8,
      },
    },
  },
  btnLinkTree: {
    background: "#ffffff",
    padding: "0 2rem",
    transition: "0.2s all ease-in-out",
    border: "none",
    boxShadow: "none",
    "& span": {
      flexDirection: "row-reverse",
      textTransform: "initial",
    },
    "& p": {
      fontSize: "1.2rem",
      fontWeight: 500,
      color: "rgb(1, 33, 74)",
      lineHeight: 1.501,
    },
  },
  btnUser: {
    textTransform: "initial",
    "& p": {
      fontSize: "1.2rem",
    },
  },
  wrapperIcon: {
    background: palette.primary.main,
    padding: "0.5rem",
    borderRadius: "2rem",
    display: "flex",
    alignItems: "center",
  },
  translate0: {
    transform: "translate(0) !important",
    opacity: "1 !important",
  },
  translateRight: {
    transform: "translateX(30rem)",
    opacity: 0,
  },
  drawerPaper: {
    background: "linear-gradient( -45deg, rgb(13,24,52) 0%, rgb(8,12,24) 99%)",
    width: "100% !important",
    height: "100vh",
    flexShrink: 0,
    zIndex: 999,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  boxMenuMobile: {
    background: "linear-gradient( -45deg, rgb(13,24,52) 0%, rgb(8,12,24) 99%)",
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    zIndex: 999,
  },
  wrapperLinksMenuMobile: {
    marginTop: "4rem",
  },
  itemMenu: {
    background: "transparent",
    border: "none",
    display: "flex",
    gap: "3rem",
    alignItems: "center",
    "& p": {
      color: "white",
      fontSize: "2.4rem",
      textTransform: "uppercase",
      fontWeight: 500,
      letterSpacing: "0.5rem",
    },
  },
  wrapperBottomMenuMobile: {
    // padding: "3rem",
    border: "1px solid rgb(21, 37, 84)",
    "& hr": {
      background: "rgb(21, 37, 84)",
      borderColor: "rgb(21, 37, 84)",
    },
  },
  btnSignIn: {
    padding: "1rem 2rem",
  },
  btnRegister: {
    padding: "1rem 2rem",
    background: "linear-gradient( 360deg, #495a68 0%, #000000 100%)",
    border: "none",
  },
  boxUserMenu: {
    minWidth: "35rem",
    height: "24rem",
    position: "absolute",
    zIndex: 100,
    top: "130px",
    right: "20px",
    borderColor: "#0FBEFD",
    padding: "2rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    [breakpoints.down(580)]: {
      top: "90px",
    },
  },
  contentUserMenu: {
    display: "flex",
    gap: "2rem",
    "& img": {
      width: "77px",
      height: "77px",
      borderRadius: "50%",
      "&:hover": {
        opacity: 1,
      },
    },
  },
  detailsContentUserMenu: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  username: {
    color: "white",
    fontWeight: 600,
    fontSize: "1.6rem",
  },
  textUserMenu: {
    color: "#959595",
    fontWeight: 600,
    fontSize: "1.2rem",
  },
  btnsUserMenu: {
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
  },
  btnAccountUserMenu: {
    background: "#091E31",
    boxShadow: "none",
    "& span": {
      fontSize: "1.4rem",
    },
  },
  btnLogout: {
    background: "#1B0F1D",
    border: "1px solid #FC0001",
    boxShadow: "none",
    "& span": {
      fontSize: "1.4rem",
    },
  },
}));
