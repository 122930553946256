import React from "react";

import Proptypes from "prop-types";
import ModalCenter from "../../atoms/ModalCenter/modal-center";
import { Box, Typography, makeStyles } from "@material-ui/core";

export default function ModalCenterTeam({ open, onClose, member }) {
  const classes = useStyle();

  return (
    <ModalCenter
      open={open}
      onClose={onClose}
      classNamePaper={classes.paper}
      classNameChildren={classes.container}
    >
      <Box className={classes.wrapperPhoto}>
        <img alt={member.name} src={member.pic} width={160} height={160} />
      </Box>
      <Typography variant="body1" className={classes.nameTeam}>
        {member.name}
      </Typography>
      <Typography variant="body1" className={classes.roleTeam}>
        {member.role}
      </Typography>
      <Typography variant="body2" className={classes.bioTeam}>
        {member.bio}
      </Typography>
    </ModalCenter>
  );
}

ModalCenterTeam.propTypes = {
  open: Proptypes.bool,
  onClose: Proptypes.func,
  member: Proptypes.object,
};

const useStyle = makeStyles((theme) => ({
  paper: {
    minHeight: "40vh",
    maxHeight: "90vh",
    minWidth: "30rem",
    width: "80%",
    maxWidth: "45rem",
    borderRadius: "2.4rem",
    overflow: "auto",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  wrapperPhoto: {
    margin: "0 auto",
    display: "block",
    height: "16rem",
    width: "16rem",
    borderRadius: "9999px",
    borderStyle: "none",
    padding: 0,
    boxShadow: "0 0 20px #27c6f72b",
    "& img": {
      width: "100%",
      height: "100%",
      borderRadius: "100%",
      padding: 0,
    },
  },
  nameTeam: {
    color: "white",
    fontSize: "1.6rem",
    lineHeight: "2.4rem",
    // marginTop: "1rem",
    fontWeight: 700,
    textTransform: "uppercase",
    marginTop: "2.4rem",
  },
  roleTeam: {
    color: "white",
    fontSize: "1.4rem",
    fontWeight: 500,
    lineHeight: "2rem",
  },
  bioTeam: {
    fontWeight: 500,
    marginTop: "2.4rem",
    fontSize: "1.8rem",
    lineHeight: "2.4rem",
    textAlign: "justify",
    whiteSpace: "pre-line",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.6rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.4rem",
    },
    [theme.breakpoints.down(500)]: {
      fontSize: "1.2rem",
      lineHeight: "1.6rem",
      maxWidth: "350px",
    },
  },
}));
