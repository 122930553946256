import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./components/pages/Home";

import App from "./containers/App/App";
import Ecosystem from "./components/pages/Ecosystem";
import Gaming from "./components/pages/Gaming";
import Terms from "./components/pages/Terms";
import About from "./components/pages/About";
import PrivacyPolicy from "./components/pages/PrivacyPolicy";
import TermsRTG from "./components/pages/TermsRTG";

export default function AppRoutes(props) {
  return (
    <App {...props}>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/ecosystem" component={Ecosystem} />
        <Route exact path="/gaming" component={Gaming} />
        <Route exact path="/about" component={About} />
        <Route exact path="/terms" component={Terms} />
        <Route exact path="/terms/rtg" component={TermsRTG} />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Route component={Home} />
      </Switch>
    </App>
  );
}
